
import Vue from "vue";

interface ProvidedData {
  fieldGroup: {
    languagePrefix: string;
    computedFieldProps: Record<string, unknown>;
  };
}

export default Vue.extend({
  name: "KFieldGroup",
  props: {
    languagePrefix: {
      required: false,
      type: String,
      default: "",
    },
    fieldProps: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  provide(): ProvidedData {
    return {
      fieldGroup: {
        languagePrefix: this.languagePrefix,
        computedFieldProps: this.computedFieldProps,
      },
    };
  },
  computed: {
    computedFieldProps(): Record<string, unknown> {
      return {
        // You can place project specific field props here, every field will get those props
        outlined: true,
        dense: true,
        // validateOnBlur: true,
        color: "primary",
        hideDetails: "auto",
        class: "mb-2",
        ...this.fieldProps,
      };
    },
  },
});
